:root {
  --au-main: 128, 0, 255;
  --str-color: 163, 85, 85;
  --dex-color: 85, 163, 163;
  --con-color: 163, 85, 163;
  --int-color: 85, 163, 85;
  --wis-color: 85, 85, 163;
  --cha-color: 163, 163, 85;
}

body {
  margin: 0;
  font-family: serif;
  background: linear-gradient(to top, #380071, black, black);
  color: white;
  font-size: 2rem;
}

button {
  font-size: 2rem;
  font-family: serif;
  color: white;
  background: rgb(var(--au-main));
  border: none;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 1);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button:not(:disabled):hover {
  filter: brightness(120%);
}

.app {
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
}

.footer {
  margin-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.portrait .footer {
  margin-top: 1rem;
}

.footer img {
  width: 3rem;
  height: 3rem;
  margin: 0 1rem;
}

.page-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.stat-pickers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.stat-picker {
  margin: 1.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid rgb(var(--stat-color));
  border-radius: 2rem;
  background: linear-gradient(to top, rgba(var(--stat-color), 0.3), black);
}

div.stat-picker.stat-color {
  background: none;
}

.stat-picker-label {
  text-transform: uppercase;
  color: rgb(var(--stat-color));
  filter: brightness(200%);
}

.stat-picker-buttons {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.stat-picker-buttons button {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(var(--stat-color));
}

.stat-picker-buttons div {
  margin: 0 1rem;
}

.stat-picker .circle-value {
  filter: brightness(120%);
  width: 2rem;
  height: 2rem;
}

.circle-value {
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid rgb(var(--stat-color));
  background: rgba(0, 0, 0, 0.2);
}

.points {
  display: flex;
  flex-direction: row;
  align-items: center;
  --stat-color: var(--au-main);
  font-size: 2.5rem;
  margin-top: 1.5rem;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}

.points.small {
  flex-direction: column;
  margin-top: 1rem;
}

.points .circle-value {
  width: 3rem;
  height: 3rem;
  margin: 0 2rem;
}

.points .button-container {
  flex-grow: 1;
  width: 0;
  display: flex;
  justify-content: flex-end;
}

.points.small .button-container {
  width: auto;
  margin: 1rem 0;
}

.generation {
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 0;
  align-items: flex-start;
}

.small .generation {
  width: auto;
}

.generation label {
  margin: 0.25rem 0;
}

.generation input {
  margin: 0 0.5rem 0 0;
}

.portrait {
  font-size: 1.5rem;
}

.portrait.app {
  padding: 0.5rem;
}

.portrait button {
  font-size: 1.5rem;
  padding: 0.25rem 0.5rem;
}

.portrait .stat-picker {
  margin: 0.25rem;
  padding: 0.5rem;
}

.portrait .stat-picker-buttons {
  margin: 0.5rem 0;
}

.portrait .stat-picker-buttons button {
  width: 2.5rem;
  height: 2.5rem;
}

.portrait .circle-value {
  padding: 0.25rem;
  margin: 0 0.5rem;
}

.portrait .points {
  font-size: 2rem;
}

.str {
  --stat-color: var(--str-color);
}

.dex {
  --stat-color: var(--dex-color);
}

.con {
  --stat-color: var(--con-color);
}

.int {
  --stat-color: var(--int-color);
}

.wis {
  --stat-color: var(--wis-color);
}

.cha {
  --stat-color: var(--cha-color);
}
